/*
Copyright (C) 2019 Cryptium Corporation. All rights reserved.
*/
/* eslint-disable no-console, import/prefer-default-export */

/**
 * Adds this to the document head section: <script src="https://js.stripe.com/v3/stripe.js"></script>
 * When the javascript has loaded, the `onLoad` callback will be invoked. The `Stripe` class will
 * be available when `onLoad` is invoked.
 *
 * @param {*} document
 * @param {*} onLoad
 */
async function loadStripeJavascript(document, onLoad) {
    if (typeof Stripe === 'object') { // eslint-disable-line no-undef
        console.log('loadStripeJavascript: already loaded');
        if (typeof onLoad === 'function') {
            onLoad();
        }
        return;
    }
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://js.stripe.com/v3/stripe.js';
    if (typeof onLoad === 'function') {
        script.onload = onLoad;
    }
    document.head.appendChild(script);
}

export { loadStripeJavascript };
