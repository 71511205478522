<template>
    <AppLayout>
        <RequireLogin>
            <v-row justify="center" class="py-5" v-show="!isSubscribed">
                <h1 class="text-h6 font-weight-light">Enterprise Subscription</h1>
            </v-row>
            <v-row justify="center" class="py-5 text-center" v-show="isAccountCreateError">
                <h1 class="text-h6 font-weight-light red--text">Request failed</h1>
            </v-row>
            <v-row justify="center" class="py-5 text-center" v-show="isPaymentError">
                <h1 class="text-h6 font-weight-light red--text">Payment failed</h1>
            </v-row>
            <v-row justify="center" class="py-5" v-show="isSubscribed">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4">
                    <h1 class="text-h6 font-weight-light text-center">Thank you</h1>
                    <p class="text-body-1 font-weight-light text-center pt-10 pa-5">Click the button below to continue to the Enterprise dashboard.</p>
                    <v-row justify="center">
                        <v-btn class="pa-5 text-center blue white--text" elevation="12" to="/dashboard">Continue</v-btn>
                    </v-row>
                </v-col>
            </v-row>
                <v-row justify="center" class="py-5" v-show="!isSubscribed && !isBilling">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5" id="payment-form">
                        <v-form v-model="accountForm" @submit="continueToBilling" onSubmit="return false;" @keyup.enter.native="continueToBilling">
                            <v-row justify="center" class="pb-2">
                                <p class="text-h6 font-weight-light">Account Information</p>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                v-model="companyName"
                                label="Company Name"
                                :rules="companyNameRules"
                                required
                                outlined
                                autofocus>
                                </v-text-field>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                    v-model="name"
                                    label="Your name"
                                    :rules="nameRules"
                                    required
                                    outlined>
                                ></v-text-field>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                    v-model="email"
                                    label="Your email"
                                    :rules="emailRules"
                                    required
                                    outlined
                                ></v-text-field>
                            </v-row>
                            <v-row justify="center" class="pt-3 pb-5">
                                <v-btn elevation="12" class="blue white--text" @click="continueToBilling" :disabled="!accountForm || !isClientReady">Continue</v-btn>
                            </v-row>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5" v-show="!isSubscribed && isBilling">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5" id="payment-form">
                        <v-form v-model="billingForm" ref="billingFormRef" @submit="validateBillingForm" onSubmit="return false;" @keyup.enter.native="validateBillingForm">
                            <v-row justify="center" class="pb-2">
                                <p class="text-h6 font-weight-light">Billing Information</p>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                v-model = "address"
                                label="Company Address"
                                :rules="addressRules"
                                required
                                outlined
                                ref="addressInputRef">
                                </v-text-field>
                            </v-row>
                            <v-row justify="center" class="px-5">
                                <v-text-field
                                v-model = "city"
                                label="City"
                                :rules="cityRules"
                                outlined
                                required>
                                </v-text-field>
                            </v-row>
                            <v-row justify="center">
                                <v-col class="px-5">
                                    <v-text-field
                                    v-model = "state"
                                        label="State"
                                        :rules="stateRules"
                                        outlined
                                        required>
                                    </v-text-field>
                                </v-col>
                                <v-col class="px-5">
                                    <v-text-field
                                        v-model = "zipCode"
                                        label="Zip Code"
                                        :rules="zipCodeRules"
                                        outlined
                                        required>
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="px-5">
                                <v-combobox
                                    v-model="defaultCountry"
                                    :items="countries"
                                    label="Country"
                                    default="United States"
                                    :rules="countryRules"
                                    outlined
                                    required
                                ></v-combobox>
                            </v-row>
                        </v-form>
                        <v-row justify="center">
                            <v-tabs slider-color="blue" fixed-tabs v-model="activeTabIndex">
                                <v-tab>Credit Card</v-tab>
                                <!--
                                <v-tab>Invoice</v-tab>
                                -->
                                <v-tab-item class="pb-8">
                                    <v-form v-model="stripeForm" ref="stripeFormRef" @submit="validateBillingForm" onSubmit="return false;">
                                        <v-row justify="center" class="pt-6 pb-2 text-body-2 text-center font-weight-light">
                                            <p>This card will be charged monthly at <strong>$0.0075 per login</strong>, $300 minimum</p>
                                        </v-row>
                                        <v-row justify="center" class="pt-2 px-5">
                                            <div id="card-element" style="width: 100%">
                                                <!-- Stripe Element will be inserted here -->
                                            </div>
                                            <div id="card-errors" role="alert" class="red--text pt-1">
                                                <!-- Used to display form errors -->
                                            </div>
                                        </v-row>
                                    </v-form>
                                </v-tab-item>
                                <!--
                                <v-tab-item class="pb-4">
                                    <v-form v-model="invoiceForm" ref="invoiceFormRef" @submit="validateBillingForm" onSubmit="return false;">
                                        <v-row justify="center" class="pt-6 pb-2 text-body-2 text-center font-weight-light">
                                            <p>We will send an invoice to this email</p>
                                        </v-row>
                                        <v-row justify="center" class="pt-2 px-5">
                                            <v-text-field
                                                v-model="invoiceEmailAddress"
                                                label="Invoice Email Address"
                                                :rules="invoiceEmailRules"
                                                outlined>
                                            </v-text-field>
                                        </v-row>
                                    </v-form>
                                </v-tab-item>
                                -->
                            </v-tabs>
                        </v-row>
                        <v-row justify="center" class="pb-5">
                            <v-btn elevation="12" class="blue white--text" v-show="activeTabIndex === 0" @click="subscribe" :disabled="!billingForm || !stripeForm || !isCreditCardFormComplete">Subscribe</v-btn>
                            <v-btn elevation="12" class="blue white--text" v-show="activeTabIndex === 1" @click="subscribe" :disabled="!billingForm || !invoiceForm">Subscribe</v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </RequireLogin>
    </AppLayout>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import loginshield from '@/client';
import { loadStripeJavascript } from '@/sdk/stripe-util';
import RequireLogin from '@/components/RequireLogin.vue';
import AppLayout from '@/components/AppLayout.vue';
import { isValidName, isValidEmail, compact } from '@/sdk/input';
import { ACCOUNT_TYPE_ENTERPRISE } from '@/constants';

let stripe;
let card;

export default {
    components: {
        RequireLogin,
        AppLayout,
    },
    data() {
        return {
            isClientReady: false,
            isPaymentError: false,
            isAccountCreateError: false,
            isSubscribed: false,
            isBilling: false,
            accountForm: false,
            billingForm: false,
            stripeForm: false,
            invoiceForm: false,
            isCreditCardFormComplete: null,
            defaultCountry: 'United States',
            companyName: null,
            name: null,
            email: null,
            accountId: null,
            address: null,
            city: null,
            state: null,
            zipCode: null,
            stripeObject: { name: null, value: null },
            countries: [
                'United States',
            ],
            invoiceEmailAddress: null,
            activeTabIndex: 0,
            companyNameRules: [
                (v) => (v && compact(v).length > 0) || 'Enter the name of the organization',
                (v) => !v || !this.compactEnterpriseAccountNames.includes(compact(v)) || 'You already have an enterprise account with a similar name',
            ],
            nameRules: [
                (v) => isValidName(v) || 'Enter your name',
            ],
            emailRules: [
                (v) => isValidEmail(v) || 'Enter an email address to receive account notifications',
            ],
            invoiceEmailRules: [
                (v) => isValidEmail(v) || 'Enter an email address tor receive billing invoices',
            ],
            addressRules: [
                (v) => (v && compact(v).length > 0) || 'Enter address',
            ],
            cityRules: [
                (v) => (v && compact(v).length > 0) || 'Enter city',
            ],
            stateRules: [
                (v) => (v && compact(v).length > 0) || 'Enter state',
            ],
            zipCodeRules: [
                (v) => (v && compact(v).length > 0) || 'Enter zip code',
            ],
            countryRules: [
                (v) => (v && compact(v).length > 0) || 'Enter country',
            ],
        };
    },

    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            stripeTokenPublicKey: (state) => state.serviceContext.stripeTokenPublicKey,
        }),
        ...mapGetters({
            currentAccount: 'account',
            enterpriseAccountList: 'enterpriseAccountList',
        }),
        compactEnterpriseAccountNames() {
            return this.enterpriseAccountList.map((item) => compact(item.name));
        },
    },

    watch: {
        isReady(value, oldValue) {
            console.log('subscribeEnterprise: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    methods: {
        init() {
            if (this.currentAccount.name) {
                this.name = this.currentAccount.name;
            }
            if (this.currentAccount.email) {
                this.email = this.currentAccount.email;
            }
        },
        initStripe() {
            stripe = new Stripe(this.stripeTokenPublicKey); // eslint-disable-line no-undef
            // Create an instance of Elements.
            const elements = stripe.elements();
            // Add an instance of the card Element into the `card-element` <div>. (May want to change)
            const style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            };
            // Create an instance of the card Element.
            card = elements.create('card', { style });
            // Add an instance of the card Element into the `card-element` <div>.
            card.mount('#card-element');
            // Handle real-time validation errors from the card Element.
            card.addEventListener('change', (event) => {
                const displayError = document.getElementById('card-errors');
                if (event.error) {
                    displayError.textContent = event.error.message;
                    this.isCreditCardFormComplete = false;
                } else {
                    displayError.textContent = '';
                    this.isCreditCardFormComplete = true;
                }
            });
        },
        async continueToBilling() {
            // API Calls to make account
            this.isAccountCreateError = false;
            this.isBilling = true;
            this.initStripe();
            window.scrollTo(0, 0);
            this.$nextTick(() => this.$refs.addressInputRef.focus());
            this.$nextTick(() => this.$refs.billingFormRef.reset());
        },
        validateBillingForm() {
            const isBillingFormValidated = this.$refs.billingFormRef.validate();
            if (isBillingFormValidated) {
                const isBillingWithStripeValidated = this.isCreditCardFormComplete && this.$refs.stripeFormRef.validate();
                const isBillingWithInvoiceValidated = false; // this.$refs.invoiceFormRef.validate();

                if (isBillingWithStripeValidated || isBillingWithInvoiceValidated) {
                    this.subscribe();
                }
            }
        },
        // Stripe Elements
        subscribe() {
            this.isAccountCreateError = false;
            this.isPaymentError = false;
            if (this.activeTabIndex === 0) {
                this.subscribeWithCreditCard();
            }
            if (this.activeTabIndex === 1) {
                // TODO: this.subscribeWithInvoice()
                console.log(`Send an invoice to: ${this.invoiceEmailAddress}`);
            }
        },
        subscribeWithCreditCard() {
            const {
                name, email, companyName,
            } = this;
            const address = {
                line1: this.address, city: this.city, state: this.state, country: this.country, postal_code: this.zipCode,
            };
            stripe.createToken(card).then(async (result) => {
                if (result.error) {
                    // Inform the user if there was an error.
                    const errorElement = document.getElementById('card-errors');
                    errorElement.textContent = result.error.message;
                } else {
                    // Send the token to your server.
                    console.log('stripeToken = %o', result.token.id);
                    this.$store.commit('loading', { stripeProcessingTransaction: true });
                    const response = await loginshield.stripe.createSubscription({
                        stripeToken: result.token.id, subscriptionType: ACCOUNT_TYPE_ENTERPRISE, accountName: companyName, name, email, address,
                    });
                    console.log('createSubscription response: %o', response);
                    if (response.fault || response.error) {
                        if (response.error === 'payment-failed') {
                            this.isPaymentError = true;
                        } else {
                            this.isAccountCreateError = true;
                        }
                    } else {
                        await this.$store.dispatch('loadAccount');
                        await this.$store.dispatch('switchAccount', response.accountId);
                        this.isSubscribed = true;
                        window.scrollTo(0, 0);
                    }
                    this.$store.commit('loading', { stripeProcessingTransaction: false });
                    console.log('createSubscription: %o', response);
                }
            });
        },
    },
    created() {
        this.$store.commit('loading', { stripeClient: true });
        loadStripeJavascript(document, () => {
            this.isClientReady = true;
            this.$store.commit('loading', { stripeClient: false });
        });
    },
    mounted() {
        console.log('mounted');
        if (this.isReady) {
            this.init();
        }
    },
};
</script>

<style scoped>
    .StripeElement {
        box-sizing: border-box;

        height: 40px;

        padding: 10px 12px;

        border: 1px solid transparent;
        border-radius: 4px;
        background-color: white;

        box-shadow: 0 1px 3px 0 #e6ebf1;
        -webkit-transition: box-shadow 150ms ease;
        transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }
</style>
